.app-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.search-results {
  margin-top: 20px;
}

.result-container {
  text-align: center;
  padding: 20px;
  border-radius: 10px;
  background-color: #f0f2f5; 
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); 
  width: auto;
  margin: 0 auto;
  max-width: 90%;
  animation: fadeInScale 0.5s ease-in-out;
}

.result-subcontent {
  margin-bottom: 30px;
}

.result-text {
  font-size: 16px;
  margin-bottom: 20px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  line-height: 1.5;
}

.result-header {
  font-size: 20px;
  margin-bottom: 10px;
}

.document-list {
  list-style: none;
  padding: 0;
}

.document-list li {
  font-size: 16px;
  margin-bottom: 5px;
}

@keyframes fadeInScale {
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.expanded-panel {
  background-color: #fff;
}

.expanded-panel:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1); 
}

.ant-collapse-item {
  transition: transform 0.3s ease;
}

.ant-collapse-item:hover {
  transform: scale(1.01); 
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  background-color: #f5f5f5; 
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.card-container .ant-collapse-item {
  width: calc(100% - 16px);
  margin-bottom: 16px;
}
