.splash-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: black;
  font-family: "Major Mono Display", monospace;
  color: white;
}

.splash-text {
  animation: fadeIn 1s ease-in-out;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  font-size: xx-large;
  margin-bottom: 80px;
}

.splash-subheading {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  font-family: sans-serif;
}

.splash-subheading h2 {
  margin-right: 20px;
  font-family: Garamond, serif;
  font-size: 25px;
}

.avatar-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.avatar-logo {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid black
}



.btn-container {
  text-align: center;
  padding: 21px;
}

.organisations-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.ant-card {
  height: 100px;
  margin: 16px;
  border-bottom: "none";
  border-radius: 18px;
}

:where(.css-djtmh8).ant-card .ant-card-head {
  border-bottom: none !important;
}

:where(.css-dev-only-do-not-override-djtmh8).ant-card .ant-card-head {
  border-bottom: none;
}

.selected-card {
  background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.15) 0%,
      rgba(0, 0, 0, 0.15) 100%
    ),
    radial-gradient(
        at top center,
        rgba(255, 255, 255, 0.4) 0%,
        rgba(0, 0, 0, 0.4) 120%
      )
      #989898;
  background-blend-mode: multiply, multiply;
  color: white !important;
  animation: cardSelectedAnimation 0.5s ease-in-out;
}

.splash-separator {
  height: 1px;
  background-color: gray;
  margin: 10px 188px;
  padding: 0 270px;
}

.splash-separator.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.selected-title {
  color: white;
}

@keyframes cardSelectedAnimation {
  0% {
    transform: scale(1) rotate(0deg);
    opacity: 1;
    box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.5);
  }
  50% {
    transform: scale(1.1) rotate(0deg);
    opacity: 0.8;
    box-shadow: 0px 0px 20px rgba(255, 255, 255, 0.8);
  }
  100% {
    transform: scale(1) rotate(0deg);
    opacity: 1;
    box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.5);
  }
}


.transition-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9999; 
  animation: fadeInOut 2s ease-in-out; 
}

@keyframes fadeInOut {
  0%, 100% {
    opacity: 0; 
  }
  50% {
    opacity: 1; 
  }
}


.logout-button {
  position: absolute;
  top: 20px;
  right: 20px;
  color: white;
  z-index: 2; 
}

.logout-button:hover {
  color: white !important;
}
