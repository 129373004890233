.side-nav-bar {
  background: black;
  box-shadow: rgb(38, 57, 77) 0px 20px 30px 5px !important;
  color: #ffffff;
  width: 265px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 33px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1034;
  overflow-y: auto;
  transition: width 0.3s ease-in-out;
}

.separator {
  width: 100%;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.1);
  margin: 10px 0;
}

.chats-section {
  padding-top: 40px;
  color: white;
  font-size: 15px;

}

.assistantHeadLine {
  font-weight: bold;
}




.collapse-button {
  color: #ffffff;
  cursor: pointer;
  text-align: center;
  padding: 10px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1001; 
}

.logo {
  margin-top: 2px;
  margin-bottom: 30px;
  margin-top: -26px;
  font-size: 28px;
  font-weight: bold;
  text-align: center;
}

.nav-menu {
  list-style: none;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -25px;
  overflow-y: auto; 
  max-height: calc(100vh - 210px); 
}


.nav-item {
  width: 100%;
  padding: 10px 6px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  align-items: center;
}
.nav-item:last-child {
  border-bottom: none;
}



.nav-item a {

  color: #ffffff;
  text-decoration: none;
  font-size: 15px;
  text-align: center;
  text-wrap: nowrap;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s, opacity 0.3s; 
}

.nav-item a:hover {
  background-color: #e0e0e0; 
  padding: 12px 3px;
  color: #333; 
  opacity: 0.7; 
}

.nav-item a.selected {
  background-color: white; 
  padding: 12px 3px;
  color: #333; 
}

.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px;
  text-align: center;
  font-size: 14px;
  
}

.footer .nav-menu{
  text-align: center;
  list-style:none;
  margin-left: 27px;


}

.side-nav-bar.collapsed {
  width: 85px;
}

.logo h1 {
 margin: 0px;
}

.logo h1:after {
  content: '';
  display: block;
  font-size: 18px;
  margin-top: -10px;
}
.show-less {
  font-size: 13px;
  cursor: pointer;
  display: block;
  margin-top: 5px;
  color: white;
  transition: all 0.3s ease;
  animation: pulse 1s infinite alternate; 
}

.show-less-chats {
  font-size: 13px;
  cursor: pointer;
  display: block;
  margin-top: 5px;
  color: white;
  transition: all 0.3s ease;
  padding-bottom: 20px;
}

.show-less-chats:hover {
  animation: pulse 1s infinite alternate; 
}


.side-nav-bar .show-less-chats {
  transform: translateY(0);
}

.side-nav-bar.collapsed .show-less-chats {
  transform: translateY(20px);
}
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}

.side-nav-bar.collapsed .show-less {
  font-size: 7px;
}

.side-nav-bar.collapsed .show-less:hover {
  animation: bounce 0.5s ease infinite;
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0);
  }
}

.side-nav-bar .show-less {
  transform: translateY(0);
}

.side-nav-bar.collapsed .show-less {
  transform: translateY(20px);
}



.side-nav-bar.dark-mode {
  background: white; 
  color: black; 
}

.dark-mode-switch {
  display: flex;
  align-items: center;
  background-color: lightgrey;
  margin-top: 20px;
  padding: 0 20px;
}


.side-nav-bar.dark-mode .nav-item a {
  color: black; 
}

.side-nav-bar.light-mode .nav-item a {
  color: black; 
}

.side-nav-bar.light-mode .top-nav-bar  {
  background: white !important;
  color: black !important;
}



.side-nav-bar.dark-mode .nav-item a:hover {
  color: white; 
}

.collapsed-light-mode {
  color: black !important;
}



.side-nav-bar .nav-item:hover .assistant-chats {
  display: block;
}



.side-nav-bar .assistant-chat-name {
  display: inline-block;
  list-style: none;
  padding: 5px 10px; 
  margin-left: 4px;
  border-radius: 10px;
  background-color: #1890ff; 
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
  color: #ffffff; 
  font-size: 12px; 
  white-space: nowrap; 
  overflow: hidden; 
  text-overflow: ellipsis;
  transition: background-color 0.3s ease, transform 0.3s ease;
}
.chat-history-text {
  text-align: center;
  font-style: italic;
  margin-top: 3px;
  color: white;
  font-style: normal;
  font-size: 10px;
}

.side-nav-bar .assistant-chat-name:hover {
  background-color: #40a9ff;
  transform: translateY(-2px); 
}

.side-nav-bar.collapsed .nav-item:hover .nav-icon {
  transform: scale(1.1);
}


.side-nav-bar.collapsed .chat-history-text {
  display: none;
}
.side-nav-bar .assistant-chats {
  padding-left: 0; 
  margin-top: 22px;
  list-style: none;
  cursor: pointer;
}

.side-nav-bar.collapsed .assistant-chats {
  display: none;
}

.side-nav-bar.collapsed .assistantHeadLine {
  display: none;
}


.side-nav-bar.collapsed .nav-item:hover .assistant-chats {
  display: none;
}



.side-nav-bar.collapsed .nav-item {
  margin-right: 64px;
}




.assistant-chats-wrapper {
  margin-left: 0px;
}

.assistantHeadLine {
  text-align: left;
}


.side-nav-bar.collapsed .nav-icon {
  font-size: 24px; 
  transform: scale(0.2); 
  transition: transform 0.3s ease;
  margin-right: 64px;
}

.side-nav-bar.collapsed .nav-item:hover .nav-icon {
  transform: scale(1.1);
}


.side-nav-bar.collapsed .new-chat-container{
  display: none;
  
}
.new-chat-container {
  background-color: white;
  color: black;
  padding: 6px 26px;
  border-radius: 8px;
  margin-top: 10px;
  cursor: pointer;
  display: inline-block;
  transition: background-color 0.3s;

}

.new-chat-container:hover {
  background-color: lightgrey;
}

.new-chat-label {
  margin: 0;
  padding: 0px 16px;
  white-space: nowrap;
  font-size: .875rem;
  line-height: 1.25rem;

}

.icon-label-container {
  display: flex;
  align-items: center; 
}
