.top-nav-bar {
  background: black;
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 78px);
  transition: width 0.3s ease;
  position: fixed;
  top: 0;
  left: 250px;
  z-index: 1000;
}

.nav-content {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 60px;
}


.createAssistant {
  margin-right: 556px;
}


.header {
  display: flex;
  align-items: center;
}

.logo {
  margin-right: 20px;
}

.menu-items {
  
  margin-left: auto; 
  margin-right: 189px; 
  margin-top: 12px;
  display: flex;
  align-items: center; 

}

.menu-items ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center; 
}

.menu-items li {
  margin-right: 20px;
  margin-left: 20px;
}

.menu-items a {
  color: #ffffff;
  text-decoration: none;
  font-size: 16px;
  transition: color 0.3s ease;
}

.menu-items a:hover {
  color: grey;
}

.logout-darkmode {
  display: flex;
  align-items: center;
}

.logout {
  cursor: pointer;
  margin-right: 20px;
}

.dark-mode-switch {
  display: flex;
  align-items: center;
}

.dark-mode-switch label {
  margin-left: 5px;
}

.side-nav-bar.collapsed ~ .top-nav-bar {
  left: 78px;
}

.side-nav-bar.collapsed + .content {
  width: calc(100% - 80px);
}


.ant-select.custom-select .ant-select-selector {
  background-color: black;
  color: white;

}
.ant-select.custom-select {
  height: 40px;
}




.profile-icon {
}

.profile-icon .anticon {
  font-size: 25px;
  color: #ffffff;
}

.ant-select-selection-placeholder {
  color: white;
}


.side-nav-bar.collapsed ~ .top-nav-bar .menu-items {
  margin-right: 6px;
}

.side-nav-bar.collapsed ~ .top-nav-bar .createAssistant {
  margin-right: 756px;
}


.side-nav-bar.light-mode ~ .top-nav-bar {
  background: white;
  color: black;
}





