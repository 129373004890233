.splash-container {
    position: fixed; 
    top: 0;
    left: 0;
    width: 100%; 
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black; 
    font-family: "Major Mono Display", monospace;
  }
  
  .splash-text {
    animation: fadeIn 1s ease-in-out; 
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); 
    color: white; 
    font-size: xx-large;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  