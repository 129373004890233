
.upload-card {
    height: 80px !important;
    width: 174px;
    margin-right: 10px;
    cursor: pointer;
  }
  
  .selected-cardd {
    background-color: #1890ff;
   
  }

  .selected-cardd .ant-card-meta-title {
    color: white !important;

  }
  
  .selected-cardd:hover {
    background-color: #1890ff;
  }
  
  .upload-card:hover {
    background-color: #f0f2f5;
    color: black;
  }
  
  
  
  
  