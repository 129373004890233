
.chatbot-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
  }
  
  .chatbot-icon {
    width: 60px;
    height: 60px;
    background-color: #000;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  }
  
  .chatbot-icon img {
    width: 25px;
    height: 25px;
    filter: grayscale(100%); 
  }


.react-chatbot-kit-chat-container {
    font-family: 'Arial', sans-serif;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    width: 315px;
  }
  

  .react-chatbot-kit-chat-inner-container {
    background-color: #f5f5f5;
    border-radius: 10px;
  }
  

  .react-chatbot-kit-chat-header {
    content: "Minsky";
    background-color: black;
    color: #ffffff;
    font-size: 15px;
    padding: 20px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }
  

  .react-chatbot-kit-chat-input-container {
    padding: 10px;
  }
  

  .react-chatbot-kit-chat-input {
    width: calc(100% - 20px);
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }

  .react-chatbot-kit-chat-input::placeholder {
    color: #aaa;
  }
  

  .react-chatbot-kit-chat-btn-send {
    background-color: gray;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    margin-left: 10px;
    cursor: pointer;
    background-image: url(../../../../assets/Images/send.png); 
    background-repeat: no-repeat;
    background-position: right 24px center; 
    background-size: 20px;

  }
  

  .react-chatbot-kit-chat-btn-send-icon {
    display: none; 
  }

  .react-chatbot-kit-chat-btn-send::after {

    color: #000;
    position: absolute; 
    top: 50%;
    left: 79%;
    transform: translate(-50%, -50%); 
  }

  .react-chatbot-kit-chat-bot-message-container {
    padding: 10px;
  }
  

  .react-chatbot-kit-chat-bot-message {
    background-color: #ffffff;
    border-radius: 5px;
    padding: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }

  .react-chatbot-kit-chat-bot-message  {
    background-color: gray !important;
    color: white;
  }

  .react-chatbot-kit-chat-btn-send {
    background-color: lightgray !important;
    margin-right: 21px;

  }

  .react-chatbot-kit-chat-bot-message-arrow {
    border-right: none;
  }
  

  

  .react-chatbot-kit-chat-bot-avatar-container {
    display: none; 
  }
  
 
  .chatbot-loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
  
  }
  

  #chatbot-loader #chatbot-loader-dot1,
  #chatbot-loader #chatbot-loader-dot2,
  #chatbot-loader #chatbot-loader-dot3 {
    width: 6px;
    height: 9px;
    border-radius: 50%;
    margin: 0 5px;
    animation: chatbot-loader-animation 1s infinite ease-in-out;
  }
  
  @keyframes chatbot-loader-animation {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
    100% {
      transform: translateY(0);
    }
  }

  
  @keyframes chatbot-icon-clicked {
    0% {
      transform: scale(1) rotate(0deg);
      opacity: 1;
    }
    25% {
      transform: scale(1.1) rotate(10deg);
      opacity: 0.7;
    }
    50% {
      transform: scale(1.2) rotate(0deg);
      opacity: 0.5;
    }
    75% {
      transform: scale(1.1) rotate(0deg);
      opacity: 0.7;
    }
    100% {
      transform: scale(1) rotate(0deg);
      opacity: 1;
    }
  }
  
  .chatbot-icon.clicked {
    animation: chatbot-icon-clicked 0.6s ease;
  }
  