.card-actions {
  position: absolute;
  top: 5px;
  right: 5px;
}

.button-container {
  display: flex;
  justify-content: flex-end;
}

.black-bordered-card {
  border: 1px solid #000000;
}

.breadcrumb-container {
  display: inline-block;
  vertical-align: middle;
  margin-top: 6px;
}

.breadcrumb-divider {
  width: 100%;
  margin-top: 5px;
  margin-bottom: 0;
}

.side-nav-bar.collapsed + .homeHeading {
  margin-right: 869px;
}


.card-folder {
  position: relative;
  width: 138px;
  height: 120px;
  margin: 0px 10px;
  background-color: #DDE0E4 !important;
  border-radius: 8px;
  border: 1px solid #ccc;
  color: white;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}


.card-folder-content p {
  font-size: medium;
  color: black;
  text-align: center;
  font-size: 400;
}
.card-folder:before {
  content: '';
  position: absolute;
  top: -40px;
  left: 50%;
  width: 80px;
  height: 40px;
  border-radius: 50% 50% 0 0;
  border: 1px solid #ccc;
  background-color: #3F96FE;
  transform: translateX(-50%);
}

.card-folder:after {
  content: '';
  position: absolute;
  bottom: -20px;
  left: 50%;
  width: 100px;
  height: 40px;
  border-radius: 0 0 20px 20px;
  border: 1px solid #ccc;
  background-color: #f0f0f0;
  transform: translateX(-50%);
}

.spinner-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


