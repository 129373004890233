.error {
    color: red;
    font-size: 13px;
}

.createChatSubmit {
    text-align: center;
    padding: 34px;

}
.btn-wide {
    width: 230px;
  }
  
  .btn-tall {
    height: 40px;
  }

  .textarea-container {
    height: 137px;
  }

.assistant-space {
  padding: 10px;

}
 