.chat-app {
  display: flex;
  margin-bottom: -5px;
}

.chat_content {
  height: auto;
}

.chat-container {
  display: flex;
  flex-direction: column;
  margin-left: 83px;
  width: 79vw;
  height: calc(105% - 15px);
  margin-top: 15px;
  background: white;
  padding: 21px;
}

.side-nav-bar.collapsed ~ .chat_content .chat-container {
  display: flex;
  flex-direction: column;
  height: calc(105% - 15px);
  margin-top: 15px;
  background: white;
  margin-left: -87px;
}

.side-nav-bar.collapsed ~ .conversation-container {
  flex-grow: 1;
  padding: 10px;
}



.conversation-container {
  overflow-y: hidden;
}

.message-form {
  display: flex;
  align-items: center;
  padding: 10px;
  margin-bottom: 2px;
  margin-left: 30px;
  position: fixed;
  bottom: 0;
  width: calc(87% - 151px);
  background-color: white;
}

.message-input {
  width: calc(100% - 40px);
  padding: 10px;
  border: 2px solid lightgray;
  /* border-radius: 5px; */
  font-size: 16px;
}

.sendChatText {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

.send-icon {
  position: absolute;
  top: 18px;
  left: 1008px;
  background-color: black;
  color: white;
  border: none;
  border-radius: 50%;
  padding: 7px;
  cursor: pointer;
  transition: background-color 0.3s ease
}

.send-icon:hover {
  background-color: #414536;
}

.avatar-container {
  display: flex;
  align-items: flex-start;
  margin-right: 2px;
}

.message {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.message-content {
  display: flex;
  flex-direction: column;
  margin-bottom: 0px;
}

.text {
  max-width: 100%;
  padding: 10px;
  border-radius: 8px;
  background-color: white;
  color: #000;
}

.message.user .avatar {
  background-color: #007bff;
  color: #fff;
}

.text {
  word-wrap: break-word;
}

.refreshChat {
  position: fixed;
  top: 630px;
  padding: 3px;
  cursor: pointer;
}

.chatbot-message {
  color: black;
  transition: transform 0.3s ease-in-out;
}

.collapse-panel {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 50px;
  padding-bottom: 14px;
}

.card-container {
  display: flex;
  height: auto;
}

.card {
  width: 200px;
  margin: 10px;
  height: 100px;
  transition: transform 0.3s ease-in-out;
  background-color: #e7e9ec;
  border: none;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.card-title {
  font-size: 7px;
}

.card-content {
  font-size: 7px;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.card-enter {
  animation: fadeInUp 0.5s ease-in-out;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
}

.dot {
  width: 6px;
  height: 6px;
  background-color: black;
  border-radius: 50%;
  margin: 0 3px;
  animation: loader-animation 1.5s infinite ease-in-out;
}

.dot:nth-child(2) {
  animation-delay: 0.2s;
}

.dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes loader-animation {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}

.error-message {
  color: #ff0000;
  font-size: 12px;
}

@keyframes typing {
  from {
    width: 0;
  }
}

.typing-text {
  overflow: hidden;
  white-space: nowrap;
  animation: typing 1s steps(30, end);
}

.text::after {
  content: ".";
  color: white;
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}
