.error-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #ffffff;
    color: #000000;
  }
  
  .error-title {
    font-size: 3rem;
    margin-bottom: 1rem;
  }
  
  .error-message {
    font-size: 20px;
    text-align: center;
    margin-bottom: 2rem;
    color: black !important;
  }
  
  .back-to-home {
    display: inline-block;
    padding: 10px 20px;
    background-color: #007bff;
    color: #ffffff;
    text-decoration: none;
    border-radius: 5px;
  }
  
  .back-to-home:hover {
    background-color: #0056b3;
  }
  