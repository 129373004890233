.table-container {
  display: flex;
}

.table {
  width: 80%;
  margin-left: 280px;
  margin-top: 65px;
  border-collapse: collapse;
  padding-bottom: 100px;
}

.table th,
.table td {
  border: 1px solid #ddd;
  padding: 12px; /* Increase padding for better readability */
  text-align: left; /* Align text to the left */
}

.table th {
  background-color: #f2f2f2;
}

.baseHeading {
  font-weight: 350;
  background-image: linear-gradient(to left, lightgrey, black);
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
}


.breadcrumb-link:hover,
.breadcrumb-link:focus,
.breadcrumb-link:active,
.breadcrumb-link.active {
  border-top: 1px solid black;
  color: #1890ff;
}





.ant-table {
  width: 100%;
  border-collapse: collapse;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid lightgrey;
}


.ant-table-placeholder {
  padding: 20px; 
  text-align: center; 
  color: #999; 
  font-style: italic; 
}



.ant-table-thead > tr > th {
  background-color: #1890ff; 
  color: #fff;
  font-weight: bold;
  padding: 12px;
  text-align: left;
  border-bottom: 2px solid lightgrey;
  border-right: 1px solid lightgrey 
}

.ant-table-tbody > tr > td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid lightgrey; 
  border-right: 1px solid lightgrey; 
}



.ant-btn {
  padding: 6px 12px;
  margin: 4px;
  border-radius: 4px;
}

.ant-btn-danger {
  background-color: #ff4d4f;
  border-color: #ff4d4f;
  color: #fff;
}

.ant-btn-danger:hover {
  background-color: #ff7875;
  border-color: #ff7875;
}

a.inactive{
  color:#000;
}

.activepaylink {
  background: #b3b3ca !important;
    border: none !important;
    padding: 8px !important;
    font-size: 14px !important;
    color: black
}


.uploadFile {
  margin-left: 790px;
}

.knowledgeNavcrumb {
  margin-top: 40px;
}


.statusTabs {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  padding: 29px 50px !important;
  height: 10px !important; 
  width: 0% !important; 
  border: none !important;
}

