.button-container {
    display: flex;
    padding: 0px;
    height: 83px;
    margin-left: 870px;
}

.content .content-space {
    padding: 30px;

}

.error-message {
    color: red !important;
}