.collection-options-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .collection-box {
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    padding: 10px;
    margin: 8px;
    cursor: pointer;
    transition: transform 0.2s, box-shadow 0.2s;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .collection-box:hover {
    transform: translateY(-3px);
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  }
  
  .collection-box p {
    margin: 0;
    font-size: 16px;
    color: #333333;
    font-weight: bold;
  }
  