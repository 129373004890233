.content {
  padding: 21px;
  margin-left: 92px;
  margin-top: 30px;
  height: 100vh;

}


.side-nav-bar.collapsed ~ .content {
    margin-left: -42px;
}

.homeHeading {
  padding: 0px 0px 12px !important;
  font-weight: 600;
  text-align: left;

 }

.content-space {
  padding: 20px;
}

