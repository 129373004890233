.loader {
    display: flex;
    justify-content: center;
  }
  
  .tile {
    width: 5px;
    height: 20px;
    border-right: 1px solid gray;
    background-color: black;
    margin: 0 2px; 
    animation: wave 1s infinite alternate;
  }
  
  .tile:nth-child(2) {
    animation-delay: 0.1s; 
  }
  
  .tile:nth-child(3) {
    animation-delay: 0.2s; 
  }
  
  .tile:nth-child(4) {
    animation-delay: 0.3s; 
  }
  
  @keyframes wave {
    0% {
      transform: scaleY(1);
    }
    50% {
      transform: scaleY(0.5); 
    }
    100% {
      transform: scaleY(1);
    }
  }
  